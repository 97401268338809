import React, { memo } from 'react'

import logo from "../../../../gr8horizons/static/images/INTELLECT_LOGO.jpg" // Tell Webpack this JS file uses this image

console.log(logo) // /logo.84287d09.png

function Logo() {
	return (<img src={logo} alt="Logo" width="40" height="40"/>)
}

// 	return (
// 	<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
// 	width="40.00pt" height="62.00pt" viewBox="0 0 320.000000 456.000000"
// 	preserveAspectRatio="xMidYMid meet">
// 	<metadata>
// 	Created by potrace 1.15, written by Peter Selinger 2001-2017
// 	</metadata>
// 	<g transform="translate(0.000000,456.000000) scale(0.100000,-0.100000)"
// 	fill="#FFA600" stroke="none">
// 	<path d="M1980 4543 c0 -8 5 -11 10 -8 6 3 10 1 10 -5 0 -6 6 -10 14 -8 15 3
// 	17 -6 9 -29 -8 -21 5 -43 22 -37 8 3 15 2 15 -4 0 -18 -68 -24 -92 -9 -26 16
// 	-48 15 -96 -4 -28 -12 -34 -12 -42 1 -6 8 -8 21 -5 28 3 8 0 10 -7 6 -8 -5 -9
// 	-2 -4 11 5 15 4 16 -8 6 -8 -7 -12 -20 -9 -29 4 -13 3 -14 -5 -3 -5 7 -14 11
// 	-19 8 -6 -4 -62 -7 -127 -8 -64 0 -118 -4 -120 -8 -2 -3 7 -11 20 -18 13 -6
// 	22 -7 18 -2 -3 5 -2 9 3 10 4 0 25 2 46 4 20 2 37 0 37 -4 0 -18 -24 -77 -37
// 	-89 -11 -11 -13 -9 -13 15 0 17 -3 23 -8 16 -8 -13 -35 -2 -32 13 1 5 -9 15
// 	-22 23 -21 12 -21 12 -5 -2 9 -8 17 -19 17 -26 0 -6 6 -14 14 -17 8 -3 18 -13
// 	22 -22 10 -22 11 -142 2 -135 -5 2 -8 11 -8 19 0 8 -4 14 -9 14 -13 0 0 -43
// 	17 -56 10 -7 13 -19 9 -34 -3 -13 1 -36 10 -53 11 -22 13 -35 5 -47 -8 -13 -9
// 	-44 -3 -71 2 -9 88 -67 111 -74 12 -4 19 -12 16 -20 -6 -15 65 -48 87 -39 7 2
// 	35 -4 60 -15 26 -11 47 -17 47 -13 0 4 13 -8 29 -25 15 -18 31 -30 35 -26 3 3
// 	6 -6 6 -21 0 -14 -7 -29 -15 -32 -8 -4 -15 -12 -15 -20 0 -8 -7 -14 -15 -14
// 	-8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 9 15 20 0 21 -26 28 -32 9 -5 -14
// 	-38 0 -38 16 0 7 -7 16 -16 19 -22 8 -26 8 -19 -4 3 -5 4 -10 1 -10 -11 0
// 	-101 45 -104 53 -2 4 -8 7 -14 7 -22 0 -119 54 -133 74 -13 17 -15 18 -15 3 0
// 	-20 -11 -22 -30 -4 -12 10 -12 9 -1 -5 7 -10 20 -18 27 -18 8 0 14 -5 14 -11
// 	0 -20 -65 27 -72 52 -3 13 -11 62 -17 108 -6 46 -17 93 -24 105 -25 37 -26 85
// 	-4 101 18 13 18 15 3 30 -9 9 -16 21 -16 27 0 5 -11 23 -23 38 -23 26 -24 27
// 	-36 9 -6 -11 -28 -63 -47 -117 -33 -88 -36 -106 -35 -187 2 -51 -4 -123 -13
// 	-165 -20 -95 -20 -153 0 -192 13 -25 14 -32 2 -40 -37 -25 -70 -38 -109 -44
// 	-56 -9 -65 -16 -94 -78 -17 -37 -24 -70 -24 -120 0 -39 -5 -74 -11 -80 -6 -6
// 	-10 -83 -10 -192 l0 -182 -57 -21 c-237 -90 -480 -286 -652 -527 -128 -180
// 	-232 -436 -245 -604 l-5 -65 13 80 c42 245 146 468 318 682 60 73 158 143 252
// 	178 37 14 83 37 102 51 18 14 37 23 42 20 13 -8 121 30 143 49 9 9 21 15 26
// 	13 16 -5 133 55 133 68 0 6 10 19 23 27 21 14 24 13 33 -1 9 -14 13 -11 29 16
// 	19 32 37 43 33 20 -2 -9 13 -12 55 -9 57 3 74 -8 41 -27 -13 -7 -10 -11 15
// 	-21 17 -7 35 -19 41 -25 16 -21 -28 -51 -100 -70 -188 -48 -202 -53 -181 -59
// 	32 -8 25 -39 -14 -66 -29 -19 -33 -26 -24 -42 8 -15 14 -17 29 -9 11 6 20 17
// 	20 24 0 8 7 17 16 20 17 7 75 -20 87 -40 4 -8 15 -10 25 -7 16 7 16 5 1 -17
// 	-9 -14 -18 -37 -21 -52 -5 -26 -3 -28 25 -28 40 0 116 -46 117 -71 0 -8 -84
// 	-11 -163 -6 -25 2 -42 9 -49 21 -12 22 7 39 35 30 20 -6 21 0 7 27 -8 13 -7
// 	25 1 41 10 18 9 20 -5 15 -11 -5 -16 -1 -16 13 0 22 -12 25 -41 9 -16 -8 -18
// 	-14 -9 -24 7 -9 8 -15 1 -19 -6 -4 -8 -13 -4 -21 3 -10 -1 -15 -15 -15 -28 0
// 	-37 16 -16 28 15 9 15 10 -5 17 -15 4 -29 1 -41 -10 -19 -17 -20 -16 -30 9 -5
// 	15 -10 36 -10 46 0 15 -18 30 -37 30 -2 0 -2 -13 2 -29 5 -22 2 -30 -12 -36
// 	-16 -6 -15 -8 7 -17 23 -9 24 -10 8 -22 -29 -21 -21 -36 20 -36 21 0 46 -6 56
// 	-13 16 -12 15 -13 -8 -12 -20 0 -32 -10 -52 -44 l-26 -43 23 7 c20 5 19 2 -11
// 	-24 l-35 -30 -14 30 c-19 38 -106 68 -143 48 -31 -17 -47 -39 -48 -68 0 -13
// 	-11 -39 -25 -57 -14 -18 -25 -39 -25 -47 0 -8 -9 -21 -19 -28 -26 -19 -38 -59
// 	-17 -59 8 0 18 4 21 10 3 5 44 30 91 56 81 44 86 45 100 28 17 -23 10 -85 -12
// 	-97 -19 -11 -18 -37 1 -37 13 0 11 -8 -7 -42 -15 -28 -23 -61 -23 -93 0 -48
// 	-1 -49 -37 -58 -41 -10 -78 9 -97 51 -14 29 -35 29 -27 -1 3 -14 -1 -33 -10
// 	-45 -8 -13 -11 -22 -5 -22 21 0 11 -28 -11 -34 -36 -9 -43 -7 -23 8 17 12 14
// 	15 -35 25 -37 8 -56 9 -58 1 -2 -6 -12 -4 -25 5 -27 19 -46 19 -130 -1 -61
// 	-15 -69 -20 -89 -55 -12 -22 -25 -39 -30 -39 -12 0 -10 6 22 70 35 68 35 71
// 	15 113 -8 18 -15 52 -17 77 -5 68 -30 66 -66 -7 -36 -70 -52 -163 -29 -163 31
// 	0 28 -32 -8 -75 -27 -34 -31 -44 -20 -49 21 -8 19 -50 -5 -96 -24 -47 -28
// 	-148 -6 -156 8 -3 17 -21 20 -40 4 -19 11 -34 15 -34 5 0 28 -47 51 -105 26
// 	-65 48 -105 57 -105 8 0 27 -14 43 -32 23 -24 27 -35 19 -50 -16 -31 14 -125
// 	55 -167 34 -36 80 -133 73 -155 -6 -17 22 -66 37 -66 8 0 29 -17 48 -39 18
// 	-21 47 -45 63 -54 17 -8 55 -44 86 -79 l57 -64 -29 -38 c-15 -21 -30 -41 -32
// 	-43 -3 -2 -35 4 -72 13 -94 24 -160 87 -280 269 -111 168 -114 173 -169 323
// 	-38 100 -62 191 -79 302 -4 24 -4 24 -6 -3 -2 -51 30 -180 75 -299 55 -150 58
// 	-155 170 -324 116 -177 208 -266 429 -417 180 -124 387 -198 645 -232 196 -26
// 	430 -5 614 56 95 32 358 164 410 206 108 88 237 206 283 260 183 212 293 471
// 	328 768 19 163 19 214 0 351 -28 201 -35 227 -122 429 -98 228 -250 418 -456
// 	573 -143 107 -240 163 -358 205 l-75 28 -67 -26 c-60 -22 -73 -24 -119 -14
// 	-29 5 -63 15 -76 21 -19 10 -26 8 -42 -8 -20 -19 -19 -19 58 -20 64 -1 93 -7
// 	170 -37 52 -21 96 -37 98 -37 2 0 4 11 4 25 0 26 13 31 58 19 14 -4 21 -12 18
// 	-20 -3 -9 29 -24 107 -50 62 -21 121 -38 132 -40 11 -1 42 -23 69 -48 27 -25
// 	52 -44 55 -41 28 17 239 -215 327 -360 40 -66 124 -241 124 -259 0 -6 11 -34
// 	24 -63 22 -46 23 -52 8 -56 -9 -2 -27 5 -39 16 l-23 20 0 -90 c0 -103 14 -189
// 	34 -212 8 -9 17 -35 21 -59 l7 -43 -29 7 c-15 3 -41 12 -56 20 -27 13 -30 11
// 	-110 -60 -99 -88 -216 -152 -248 -136 -14 8 -30 8 -54 0 -41 -14 -47 -40 -10
// 	-40 15 0 39 -7 55 -15 41 -21 91 -1 109 44 16 36 13 35 51 16 52 -27 43 -61
// 	-66 -235 -53 -85 -97 -156 -98 -157 -1 -1 -64 45 -140 103 -77 57 -142 104
// 	-145 104 -3 0 -12 -4 -19 -8 -9 -6 26 -42 115 -120 l128 -112 62 0 c50 0 63 3
// 	68 18 3 9 19 32 35 51 43 50 47 23 15 -95 -30 -110 -52 -156 -124 -264 -48
// 	-71 -51 -78 -51 -141 l0 -66 -79 -72 c-44 -39 -83 -80 -86 -91 -3 -10 -22 -29
// 	-41 -40 -18 -12 -34 -28 -34 -36 0 -21 -93 -64 -140 -64 -22 0 -59 -9 -81 -21
// 	l-40 -20 -50 38 c-27 20 -49 38 -49 39 0 1 7 21 15 44 l15 42 -45 53 -45 52
// 	15 42 c13 38 13 45 -2 68 -13 20 -22 24 -45 19 -20 -4 -44 2 -78 19 l-48 24
// 	-58 -19 c-32 -11 -66 -18 -75 -15 -10 3 -43 -1 -75 -9 l-57 -15 -78 39 c-74
// 	38 -79 42 -109 103 -31 63 -31 66 -21 140 6 42 8 82 5 90 -3 8 32 47 88 99 51
// 	47 111 111 133 142 22 30 45 54 52 51 10 -3 202 25 270 40 17 4 23 -1 28 -25
// 	4 -17 9 -30 11 -30 2 0 50 -7 105 -15 89 -14 103 -14 113 -1 6 8 58 33 116 55
// 	58 21 113 42 123 46 13 6 17 19 17 67 0 50 -3 59 -15 54 -9 -3 -41 -13 -71
// 	-22 -67 -19 -88 -8 -78 42 4 22 23 41 86 84 l79 55 -23 18 c-56 44 -68 58 -68
// 	74 0 15 -4 13 -20 -7 -19 -25 -19 -25 3 -43 l22 -19 -27 6 c-14 3 -33 9 -41
// 	12 -18 7 -31 -43 -31 -113 1 -36 -3 -42 -23 -47 -12 -4 -23 -13 -23 -20 0 -9
// 	-7 -12 -19 -9 -16 4 -19 0 -17 -19 1 -13 6 -30 11 -38 12 -20 -7 -10 -109 54
// 	-49 31 -91 54 -94 52 -8 -9 15 -38 62 -76 27 -22 44 -40 38 -40 -6 0 -12 -10
// 	-15 -22 -3 -23 -3 -23 -14 2 -13 30 -93 90 -120 90 -41 0 -123 -31 -123 -46 0
// 	-9 -13 -29 -30 -44 -25 -23 -29 -33 -24 -59 7 -33 5 -35 -58 -46 -53 -8 -47
// 	10 7 24 25 6 45 16 45 22 0 8 -4 8 -12 2 -28 -21 -96 -33 -123 -23 -27 10 -27
// 	11 -20 78 8 81 19 92 98 92 l59 0 -7 33 c-4 17 -13 42 -21 54 -21 33 -18 38
// 	37 63 64 30 119 101 119 153 0 45 15 57 18 14 2 -17 4 -32 5 -34 2 -1 33 9 69
// 	23 61 23 66 28 73 61 5 28 3 38 -10 46 -15 8 -15 10 4 24 27 18 29 59 4 48
// 	-42 -17 -49 -16 -61 10 -7 15 -10 42 -6 59 4 22 1 36 -9 44 -13 11 -17 3 -26
// 	-53 -10 -55 -9 -69 5 -96 19 -38 12 -94 -15 -108 -15 -8 -21 -4 -36 27 l-17
// 	37 -15 -22 c-40 -60 -60 14 -24 85 10 20 22 69 26 108 6 65 10 75 44 108 l37
// 	36 68 -14 c38 -8 73 -19 79 -25 14 -14 1 -28 -34 -35 -38 -7 -34 -31 6 -31 16
// 	0 30 -6 32 -12 6 -18 33 -16 33 2 0 8 5 15 11 15 8 0 10 17 7 50 -5 45 -3 48
// 	13 39 15 -8 20 -4 28 28 24 93 24 91 -4 105 l-26 14 27 13 27 12 -33 34 c-19
// 	19 -31 35 -27 35 4 0 35 -14 68 -31 66 -33 62 -36 43 32 -4 15 -9 18 -17 10
// 	-7 -7 -22 -2 -48 17 -41 29 -48 42 -24 42 24 0 17 19 -9 26 -24 6 -66 68 -66
// 	98 0 8 -11 18 -24 22 -30 7 -40 28 -21 44 8 6 17 25 20 41 5 23 0 37 -25 69
// 	-20 26 -40 40 -55 40 -12 0 -28 6 -35 14 -13 16 -273 106 -306 106 -12 0 -27
// 	7 -34 15 -11 13 -7 15 28 15 30 0 45 6 55 20 14 20 19 20 102 10 64 -8 91 -8
// 	99 0 8 8 7 10 -4 6 -8 -3 -44 -2 -80 3 l-65 10 88 -2 c48 -1 87 1 87 5 0 4 7
// 	5 16 1 15 -5 15 -3 1 23 -16 30 -7 56 20 61 17 4 25 -27 8 -34 -11 -4 -13 -9
// 	-5 -18 6 -7 8 -20 4 -29 -7 -19 14 -21 51 -4 16 7 25 19 25 35 0 12 -4 23 -8
// 	23 -13 0 -35 118 -29 157 8 45 73 114 170 180 37 25 81 62 98 82 16 20 41 47
// 	53 61 66 71 100 160 61 160 -8 0 -15 5 -15 11 0 5 6 7 13 4 6 -4 1 2 -13 13
// 	-26 21 -50 14 -50 -14 0 -19 -46 -18 -54 2 -12 31 -7 36 30 29 53 -10 39 15
// 	-22 38 -87 34 -133 60 -139 78 -3 10 -20 21 -36 24 -26 6 -30 11 -24 28 3 12
// 	7 33 9 47 6 54 39 150 52 150 26 0 58 61 58 112 0 27 -5 52 -10 56 -6 4 -11
// 	23 -12 42 -2 27 5 43 28 68 16 18 30 40 30 48 0 8 7 17 15 20 20 8 20 84 -1
// 	110 -8 10 -13 14 -10 7 4 -6 2 -14 -3 -17 -5 -3 -13 7 -19 22 -8 23 -16 29
// 	-41 29 -20 0 -31 -5 -31 -14z m-33 -277 c-7 -18 11 -86 23 -86 4 0 3 -11 -3
// 	-25 -7 -14 -17 -23 -23 -21 -7 3 -9 -5 -6 -19 2 -12 -1 -26 -8 -30 -8 -5 -11
// 	0 -8 16 2 13 -2 23 -8 22 -6 -1 -9 11 -6 30 3 18 1 29 -3 25 -12 -11 -35 11
// 	-35 33 0 23 -28 25 -37 4 -3 -9 2 -24 11 -34 9 -10 16 -22 16 -28 0 -18 -53
// 	-64 -67 -59 -7 3 -13 1 -13 -4 0 -6 -7 -10 -15 -10 -8 0 -15 -5 -15 -11 0 -8
// 	-5 -8 -15 1 -8 7 -15 20 -15 29 0 14 2 14 10 1 5 -8 10 -10 10 -5 0 6 -4 17
// 	-9 24 -7 10 -4 23 8 40 10 15 22 41 26 59 l7 32 49 -6 c29 -4 49 -3 49 3 0 13
// 	33 12 43 -1 5 -6 7 -2 4 12 -4 17 -1 22 15 22 13 0 18 -5 15 -14z m8 -729
// 	c-31 -17 -75 -67 -90 -103 -8 -19 -18 -39 -24 -46 -6 -7 -30 -37 -53 -67 -24
// 	-30 -49 -56 -55 -59 -19 -6 -5 145 16 183 20 35 135 102 184 107 40 4 47 0 22
// 	-15z m-694 -319 c7 -18 15 -37 17 -43 3 -5 -5 -1 -18 10 l-23 20 18 -28 c16
// 	-25 20 -26 28 -12 8 15 10 15 30 -2 19 -17 20 -17 14 0 -4 9 -2 17 2 17 5 0
// 	15 -23 22 -50 7 -28 18 -49 24 -47 5 3 15 -1 22 -8 9 -10 7 -14 -10 -19 -11
// 	-3 -27 0 -34 6 -23 19 -28 -2 -5 -24 19 -19 22 -20 28 -5 9 24 24 21 24 -4 0
// 	-11 -4 -18 -9 -15 -5 3 -21 -6 -35 -21 -23 -22 -29 -25 -37 -13 -6 11 -9 11
// 	-9 2 0 -7 12 -17 26 -22 39 -15 46 -12 33 13 -11 20 -11 21 5 8 14 -12 16 -12
// 	11 1 -5 11 1 14 23 10 16 -2 33 1 36 7 4 6 -1 9 -12 6 -13 -2 -18 3 -17 20 0
// 	23 15 49 15 27 0 -7 7 -12 16 -12 8 0 12 -5 8 -11 -4 -8 0 -9 15 -4 15 4 21 3
// 	19 -6 -2 -7 3 -13 10 -14 6 0 12 -4 12 -8 -1 -4 9 -17 22 -28 l23 -20 -27 4
// 	c-15 2 -25 1 -23 -4 3 -4 -34 -10 -82 -13 -49 -4 -104 -8 -123 -10 -19 -2 -29
// 	-2 -22 1 6 2 10 9 6 14 -11 18 -32 9 -52 -22 -16 -25 -20 -28 -26 -14 -5 15
// 	-9 14 -35 -4 -28 -20 -29 -20 -82 -3 l-53 18 41 17 c23 9 44 21 48 27 3 5 11
// 	10 16 10 6 0 8 -3 6 -7 -3 -5 6 -9 19 -9 13 -1 24 1 24 5 0 18 83 14 96 -4 6
// 	-8 15 -15 19 -15 5 0 -2 11 -15 25 -19 20 -20 25 -7 25 9 0 18 4 21 9 7 10
// 	-25 101 -35 101 -9 0 1 -39 12 -46 15 -9 10 -46 -8 -59 -25 -19 -43 -7 -43 28
// 	l0 30 23 -19 c12 -10 17 -12 10 -5 -6 7 -9 19 -6 26 7 19 -15 55 -32 55 -8 0
// 	-15 3 -16 8 0 4 -2 15 -4 25 -2 9 1 17 7 17 5 0 8 8 7 18 -4 24 10 72 20 72 5
// 	0 15 -15 22 -32z m267 -290 c-3 -7 -20 -14 -39 -16 -58 -5 -64 -10 -44 -32 10
// 	-11 28 -20 39 -20 62 -1 66 -6 66 -91 0 -77 -1 -79 -24 -79 -14 0 -37 -5 -52
// 	-10 -25 -10 -31 -7 -76 37 -52 51 -58 63 -30 63 10 0 30 13 45 28 26 27 26 29
// 	10 44 -10 8 -27 20 -38 26 -11 6 -14 12 -7 12 6 0 12 6 12 14 0 10 16 17 48
// 	21 26 3 54 8 62 9 29 7 32 6 28 -6z m602 -12 c37 -13 67 -32 74 -44 8 -15 15
// 	-19 26 -12 13 8 12 10 -2 10 -10 0 -18 4 -18 10 0 10 141 -63 180 -93 14 -11
// 	18 -18 10 -15 -8 3 -61 20 -118 39 -82 27 -102 37 -99 50 3 13 -4 18 -32 23
// 	-60 10 -69 8 -73 -17 l-3 -24 -68 25 -69 25 59 23 c32 12 61 23 64 23 4 1 35
// 	-10 69 -23z m-1054 -22 c27 -9 53 -20 58 -25 5 -4 -19 -21 -52 -38 -33 -16
// 	-62 -27 -66 -24 -3 3 -14 -1 -24 -10 -23 -21 -131 -59 -144 -51 -5 3 -24 -6
// 	-42 -19 -18 -14 -67 -39 -109 -55 -43 -17 -94 -43 -115 -58 l-37 -27 35 37
// 	c97 104 347 266 441 285 3 0 28 -6 55 -15z m579 -84 c39 -12 73 -25 77 -29 5
// 	-4 -13 -27 -40 -53 -44 -42 -50 -45 -89 -41 l-43 5 0 75 c0 56 3 74 13 71 6
// 	-3 44 -15 82 -28z m140 -60 c3 -6 17 -10 29 -10 25 0 76 -56 76 -83 0 -10 -22
// 	-20 -67 -30 -66 -15 -67 -15 -98 9 -18 13 -36 24 -41 24 -5 0 -16 5 -24 10
// 	-12 8 -6 18 34 59 40 40 53 48 67 40 10 -5 21 -14 24 -19z m-409 -32 l54 -51
// 	-42 -24 c-24 -13 -49 -31 -55 -40 -12 -15 -14 -15 -30 0 -13 11 -23 13 -36 6
// 	-11 -7 -17 -7 -17 0 0 6 -18 20 -40 31 -42 22 -80 15 -80 -14 0 -18 -27 -28
// 	-33 -12 -3 6 10 23 29 35 24 17 34 31 34 50 0 24 6 28 73 48 39 12 76 22 80
// 	22 5 1 33 -22 63 -51z m279 -55 c58 -21 141 -94 132 -117 -4 -9 -2 -18 3 -21
// 	6 -4 10 -31 10 -61 0 -50 -3 -58 -41 -99 -45 -49 -105 -74 -186 -78 -35 -1
// 	-54 3 -66 15 -10 9 -27 20 -39 24 -19 6 -19 7 -5 16 20 11 22 34 5 45 -7 4
// 	-64 8 -125 8 -86 1 -113 -2 -113 -12 0 -7 -7 -13 -16 -13 -13 0 -15 -8 -11
// 	-42 l6 -43 -69 -52 -70 -53 0 28 c0 20 9 35 30 50 17 13 30 31 30 42 0 11 9
// 	29 20 40 19 19 20 23 5 73 -8 28 -13 37 -10 20 3 -20 0 -33 -6 -33 -6 0 -9 -6
// 	-6 -12 2 -7 10 -12 16 -10 15 3 14 -7 -4 -25 -9 -8 -19 -29 -22 -46 -4 -18
// 	-17 -36 -34 -46 -21 -11 -29 -24 -29 -43 0 -16 -2 -28 -4 -28 -2 0 -11 -3 -20
// 	-6 -11 -4 -16 -1 -16 10 0 9 -7 16 -16 16 -12 0 -15 -6 -10 -23 3 -14 -1 -32
// 	-9 -43 -13 -16 -16 -17 -25 -4 -8 13 -10 13 -10 1 0 -12 -3 -12 -12 -3 -9 9
// 	-9 22 -1 55 7 24 10 53 7 64 -7 25 13 41 22 19 9 -24 25 -19 71 24 39 37 41
// 	43 32 67 -13 34 4 69 44 89 31 17 51 8 62 -26 6 -17 10 -18 120 -19 17 -1 44
// 	1 60 3 17 2 36 4 43 5 22 2 -9 40 -55 67 -24 14 -43 29 -43 35 0 5 10 28 23
// 	50 17 30 38 48 91 74 81 41 158 47 241 18z m228 -173 c19 0 39 -10 57 -30 15
// 	-16 34 -30 42 -30 9 -1 7 -5 -5 -14 -17 -13 -15 -16 29 -47 25 -19 53 -33 60
// 	-31 8 2 14 -5 14 -18 0 -19 -2 -19 -59 9 -32 17 -61 27 -65 24 -4 -4 10 -23
// 	30 -44 32 -32 34 -38 18 -42 -44 -12 -68 -1 -89 41 -27 53 -32 57 -49 43 -19
// 	-16 -79 17 -69 39 3 8 8 37 11 63 4 44 6 48 26 43 11 -3 34 -6 49 -6z m-989
// 	-107 c16 -3 36 -18 47 -36 18 -30 18 -32 -2 -63 -13 -22 -16 -33 -8 -36 9 -3
// 	9 -13 -1 -42 -13 -41 -6 -88 13 -85 49 6 52 -2 13 -36 -32 -29 -42 -33 -55
// 	-23 -13 10 -14 10 -4 -1 9 -10 6 -19 -15 -38 l-27 -25 29 21 29 21 37 -33 c60
// 	-51 90 -70 175 -109 44 -20 79 -40 78 -45 -1 -4 -40 -109 -87 -233 -46 -124
// 	-99 -274 -117 -335 -18 -60 -34 -112 -36 -114 -5 -7 -103 41 -168 83 -91 58
// 	-233 176 -265 220 l-29 39 20 39 c32 63 84 140 149 222 58 73 78 92 67 62 -6
// 	-16 36 -38 58 -30 15 6 18 54 3 54 -4 0 -7 -10 -5 -21 2 -25 -11 -33 -33 -19
// 	-25 16 2 45 39 44 17 -1 31 2 31 7 0 15 -52 10 -72 -8 -18 -16 -25 -7 -10 13
// 	4 5 -3 -2 -16 -16 -84 -91 -232 -304 -232 -334 -1 -41 -111 163 -129 239 -7
// 	28 -19 61 -27 75 -10 16 -13 18 -8 5 4 -11 15 -47 24 -80 20 -68 71 -174 107
// 	-221 l24 -32 -51 -102 c-27 -57 -57 -127 -66 -155 l-17 -52 -37 24 c-30 20
// 	-43 39 -65 96 -15 40 -38 91 -51 114 -13 23 -24 49 -24 58 0 8 -7 18 -15 21
// 	-23 9 -20 105 5 154 22 44 26 96 8 102 -8 3 -2 18 19 44 22 28 30 47 26 65 -3
// 	19 5 36 35 69 l39 45 7 -32 c6 -24 0 -45 -23 -92 -17 -33 -31 -65 -31 -71 0
// 	-23 29 -7 54 30 16 25 36 42 54 46 15 3 40 10 56 15 42 13 79 11 103 -6 13 -9
// 	24 -11 28 -5 5 8 62 3 74 -6 2 -2 -2 -10 -9 -19 -18 -21 -8 -27 25 -15 15 6
// 	36 8 46 5 16 -5 17 -2 9 26 -6 19 -6 38 0 50 6 9 9 24 7 31 -2 7 1 3 7 -9 5
// 	-13 14 -23 20 -23 5 0 17 -7 26 -15 13 -12 28 -14 59 -9 45 7 54 23 47 79 -4
// 	32 27 104 50 118 20 11 17 27 -3 27 -15 0 -16 -2 -3 -10 8 -5 11 -10 5 -10 -5
// 	0 -17 5 -25 10 -13 8 -13 11 2 22 27 21 32 79 9 107 l-18 24 -86 -47 c-48 -26
// 	-89 -51 -92 -56 -11 -18 -23 -11 -18 10 3 10 14 22 24 25 11 4 19 16 19 27 0
// 	12 6 29 14 37 8 9 21 29 30 45 10 20 33 37 68 52 29 13 48 23 42 24 -7 0 -31
// 	-9 -55 -19 -53 -24 -61 -19 -24 15 19 18 34 24 49 20 11 -4 34 -9 50 -13z
// 	m564 -1 c17 -3 15 -32 -3 -32 -27 0 -16 -25 18 -39 31 -12 31 -13 10 -21 -17
// 	-7 -23 -18 -23 -40 0 -16 -4 -30 -10 -30 -5 0 -10 -12 -10 -26 0 -14 -4 -23
// 	-9 -19 -14 8 -31 -28 -31 -64 0 -28 -4 -31 -57 -45 -32 -9 -77 -25 -101 -36
// 	-23 -11 -54 -20 -69 -20 -15 0 -41 -14 -64 -35 -22 -19 -46 -35 -54 -35 -48 0
// 	24 202 73 205 28 2 55 27 57 54 1 11 9 29 19 40 15 17 17 30 12 71 -6 41 -4
// 	50 9 50 8 0 15 6 15 13 0 9 27 12 103 11 56 0 108 -1 115 -2z m369 -37 c30
// 	-18 51 -41 68 -76 23 -45 28 -48 46 -38 16 8 28 7 51 -2 25 -10 28 -16 23 -37
// 	-3 -13 -9 -38 -12 -54 -4 -22 -10 -28 -24 -25 -10 3 -23 -1 -30 -9 -8 -10 -8
// 	-14 0 -14 6 0 11 -16 11 -35 0 -19 -4 -35 -10 -35 -5 0 -10 -8 -10 -17 -1 -17
// 	-1 -17 -14 0 -10 13 -21 16 -41 11 -18 -4 -26 -3 -22 3 4 6 20 13 37 16 21 5
// 	30 12 30 26 0 21 -32 36 -95 46 -39 6 -42 9 -89 99 l-48 94 38 36 c22 19 41
// 	36 43 36 2 0 24 -11 48 -25z m-95 -131 l45 -86 -47 -44 c-46 -43 -48 -47 -55
// 	-117 -4 -40 -16 -89 -26 -109 -11 -21 -19 -55 -19 -78 0 -33 3 -40 19 -40 11
// 	0 26 9 34 20 14 19 15 20 21 3 4 -10 2 -19 -3 -21 -6 -2 -11 -22 -11 -44 0
// 	-29 -9 -52 -32 -84 -35 -49 -64 -60 -54 -20 4 15 0 28 -9 36 -11 9 -15 34 -15
// 	96 0 80 1 84 23 85 21 2 21 2 1 6 -21 4 -22 10 -29 211 -4 114 -8 216 -8 226
// 	-3 25 21 32 30 9 4 -10 11 -22 15 -28 5 -5 15 -19 23 -30 13 -19 14 -19 32 -2
// 	17 18 17 19 -1 32 -17 13 -18 18 -6 40 7 14 16 25 20 25 3 0 27 -39 52 -86z
// 	m-184 49 c5 -10 13 -114 17 -232 l7 -214 -82 6 c-88 7 -239 35 -253 48 -5 4 9
// 	45 30 91 39 82 40 84 89 98 40 12 50 20 52 40 7 60 12 72 27 60 13 -11 15 -7
// 	15 19 0 17 5 31 10 31 6 0 10 16 10 35 0 33 2 35 35 35 22 0 37 -6 43 -17z
// 	m-338 -2 c0 -6 -9 -16 -20 -24 -12 -9 -18 -20 -14 -30 8 -21 -32 -60 -55 -52
// 	-14 4 -25 -9 -54 -66 -21 -39 -37 -84 -37 -100 l0 -28 -33 19 c-18 11 -43 30
// 	-55 43 l-21 22 60 50 c33 28 66 57 72 65 7 8 -3 2 -22 -13 -37 -31 -49 -30
// 	-31 2 10 20 24 25 59 23 8 0 41 22 75 49 62 49 76 56 76 40z m80 -225 c0 -4
// 	-71 -160 -75 -164 -2 -2 -33 11 -71 28 l-68 30 34 35 c24 25 43 35 64 35 17 1
// 	47 9 66 20 34 19 50 24 50 16z m1711 -108 c14 2 19 -6 24 -35 4 -21 11 -54 16
// 	-73 18 -69 7 -63 -31 18 -21 45 -41 82 -44 82 -3 0 17 -48 45 -107 48 -104 50
// 	-110 54 -213 9 -259 -47 -516 -164 -749 -55 -109 -78 -116 -40 -13 5 13 9 64
// 	9 114 l0 90 50 83 c61 101 128 278 135 354 5 55 5 55 -9 -14 -30 -144 -156
// 	-417 -177 -383 -5 7 -9 21 -9 30 0 30 -73 244 -118 349 -1 3 31 36 72 72 73
// 	64 76 65 103 52 16 -7 43 -16 62 -20 l34 -7 -8 48 c-4 27 -13 56 -21 65 -21
// 	23 -35 113 -32 201 2 66 4 75 17 65 8 -7 22 -11 32 -9z m-1668 -93 c37 -8 106
// 	-18 153 -21 l84 -7 0 -64 c0 -54 -2 -62 -14 -51 -8 7 -12 17 -9 21 3 5 0 21
// 	-7 36 -10 26 -14 27 -36 17 -21 -10 -23 -16 -17 -46 3 -19 10 -38 16 -41 5 -4
// 	6 -20 1 -40 -5 -19 -9 -38 -9 -44 0 -5 -9 -18 -20 -27 -20 -17 -19 -17 10 -17
// 	17 0 46 7 65 15 21 8 25 12 10 9 -72 -15 -85 -17 -70 -6 8 5 15 16 15 23 1 7
// 	5 30 9 52 5 23 5 41 -1 45 -13 8 -27 65 -14 57 5 -3 12 0 14 6 9 21 28 -13 22
// 	-38 -3 -12 3 -25 15 -34 11 -8 20 -19 20 -24 0 -5 7 -17 16 -27 22 -25 -3 -81
// 	-45 -99 l-31 -12 19 -32 c10 -17 21 -43 25 -58 6 -28 6 -28 -43 -28 -59 0
// 	-101 -17 -101 -40 0 -9 -3 -44 -6 -77 l-7 -60 32 -11 c22 -8 30 -16 25 -24
// 	-12 -21 -95 -128 -99 -128 -17 0 -286 65 -327 79 -41 14 -52 22 -50 37 7 45
// 	79 263 142 430 101 266 95 255 122 249 13 -2 54 -11 91 -20z m-628 -528 c50
// 	-37 126 -86 170 -109 44 -22 82 -42 84 -44 2 -2 -8 -54 -23 -116 -20 -87 -55
// 	-382 -56 -471 0 -16 -276 132 -309 167 -19 20 -40 36 -47 36 -13 0 -30 36 -26
// 	57 5 24 -41 120 -74 154 -41 42 -71 136 -55 168 7 11 7 21 0 28 -12 12 18 100
// 	78 225 l46 97 61 -62 c33 -34 102 -93 151 -130z m2063 66 c35 -92 79 -233 88
// 	-285 5 -31 0 -46 -31 -97 -37 -60 -120 -145 -183 -190 l-34 -24 20 40 c34 66
// 	75 230 65 260 -9 26 -9 26 -53 -30 l-45 -57 -55 0 -55 0 -115 101 c-112 97
// 	-141 129 -106 116 12 -5 163 -114 272 -199 8 -6 44 44 113 154 80 129 101 170
// 	101 199 0 31 -4 38 -30 49 -32 13 -38 25 -17 33 28 12 36 3 65 -70z m-823 -30
// 	c54 -34 101 -60 104 -57 2 2 -6 15 -19 29 -29 31 -35 68 -10 60 12 -4 19 1 22
// 	13 3 11 15 22 27 25 20 5 22 2 16 -22 -7 -34 23 -66 54 -56 18 5 24 -1 40 -37
// 	10 -24 16 -46 12 -49 -3 -4 -50 -23 -103 -44 -53 -20 -98 -41 -100 -45 -4 -13
// 	-190 17 -199 32 -5 7 -9 21 -9 31 0 9 -1 17 -2 17 -2 0 -41 -7 -88 -15 -47 -8
// 	-88 -15 -92 -15 -5 0 -8 18 -8 40 0 29 5 42 20 50 12 6 25 22 30 35 12 31 107
// 	55 143 36 46 -25 76 -57 81 -86 4 -16 3 -24 0 -17 -7 14 -50 16 -59 3 -3 -6 4
// 	-15 15 -21 17 -9 27 -8 47 5 14 10 28 25 30 35 3 11 12 20 20 22 10 2 -5 19
// 	-41 48 -31 25 -56 48 -56 53 0 4 6 5 13 1 6 -5 57 -36 112 -71z m-725 -263
// 	c58 -12 108 -25 113 -29 4 -4 -29 -40 -74 -80 -48 -44 -76 -77 -70 -81 8 -5 8
// 	-28 0 -85 l-11 -79 34 -65 c31 -63 37 -68 114 -108 l80 -42 58 15 c31 8 65 12
// 	75 9 10 -3 30 -1 45 4 l26 11 0 -60 0 -60 -96 0 c-159 0 -547 78 -607 122 -17
// 	13 -17 23 -2 193 9 107 25 225 41 291 l26 111 72 -22 c39 -13 118 -33 176 -45z
// 	m1618 -289 c-19 -73 -30 -95 -81 -158 -51 -64 -162 -169 -295 -280 -48 -40
// 	-191 -118 -200 -108 -4 3 2 11 13 17 10 6 28 22 39 36 12 13 58 61 104 105
// 	l82 82 0 65 c0 61 3 69 48 137 30 45 69 87 107 116 72 53 143 127 176 182 l24
// 	40 3 -74 c2 -53 -4 -99 -20 -160z m-1954 -258 c3 -10 12 -56 21 -103 8 -46 27
// 	-113 41 -148 14 -35 24 -66 22 -68 -12 -11 -254 144 -341 218 l-32 27 58 -15
// 	c32 -8 61 -13 65 -10 4 2 22 24 40 49 28 39 30 46 17 57 -9 7 -34 35 -57 62
// 	l-41 50 100 -51 c58 -29 104 -58 107 -68z m836 12 c33 -16 58 -23 77 -19 36 7
// 	58 -23 44 -59 -8 -21 -17 -25 -87 -30 -143 -13 -134 -16 -134 53 0 60 6 70 48
// 	79 1 1 25 -10 52 -24z m-645 -59 c148 -37 296 -59 423 -64 l112 -3 0 -47 c0
// 	-26 3 -104 7 -174 l6 -128 -39 0 c-161 0 -549 89 -593 135 -19 21 -61 145 -76
// 	225 -9 47 -18 93 -21 102 -5 16 -2 16 38 -2 24 -10 88 -30 143 -44z m750 -66
// 	c-4 -6 14 -35 39 -64 l46 -54 -15 -42 c-8 -23 -15 -44 -15 -45 0 -1 25 -22 57
// 	-46 l56 -44 -74 -17 c-65 -16 -279 -42 -285 -35 -1 1 -5 79 -9 174 l-8 172 59
// 	4 c129 8 155 7 149 -3z"/>
// 	</g>
// </svg>)
// }

export default memo(Logo)
